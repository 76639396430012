import React from "react"
import { injectIntl } from 'gatsby-plugin-intl';
import Link from './link';
import { Link as GatsbyLink } from 'gatsby';
import { REPORT_LINKS } from '../common/constants';

const Thanks = ({ intl, hideBack }) => {

    const { locale } = intl;
    const link = REPORT_LINKS[locale];

    return (
        <div className="block standard form thankyou" data-section="thankyou">
            <div className="container-fluid">
                <div className="row">
                    <div className="col">
                        <h2>{intl.formatMessage({ id: "thankyou_heading" })}</h2>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xl-7">
                        <p>{intl.formatMessage({ id: "thankyou_copy" })}</p>
                    </div>
                </div>
                {link ? (
                    <div className="row">
                        <div className="col-xl-7">
                            <GatsbyLink
                                to={link}
                                target="_blank"
                                rel="noopener noreferrer"
                                title="" className="button primary thankyou-download">
                                {intl.formatMessage({
                                    id: "cta_download",
                                })}
                            </GatsbyLink>
                        </div>
                    </div>
                ) : ''}
                {!hideBack ?
                    <div className="row">
                        <div className="col-xl-7">
                            <Link to="/" title="" className="link-text"><span>{intl.formatMessage({ id: "thankyou_cta" })}</span></Link>
                        </div>
                    </div>
                : ''}

            </div>
        </div>
    )
}

export default injectIntl(Thanks)
