import React, { useState } from "react"
import { injectIntl } from "react-intl"
import Footer from '../components/footer';
import PaidForm from '../components/form-paid-media';
import SEO from '../components/seo';
import Thanks from '../components/thanks';
import PaidHeader from "../components/paid-header";

const Download = ({ intl }) => {
    const [type, setType] = useState('form');

    function updateOnSuccess() {
        setType('thanks');
    }

    return (
        <>
            <SEO title="Download" />
            <PaidHeader type={type} />
            <div className="main dark">
                {/* MARKETO */}
                {type !== 'thanks' ? <PaidForm updateOnSuccess={updateOnSuccess} /> : <Thanks hideBack={true} />}

                {/* FOOTER */}
                <Footer />
            </div>
        </>
    )
}

export default injectIntl(Download)
