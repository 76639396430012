import { EMAIL_BLACKLIST } from '../common/constants';

export const emailValidate = (email = '') => {
    let valid = true;

    EMAIL_BLACKLIST.forEach((domain) => {
        const atLocation = email.indexOf('@');
        if (atLocation === -1) {
            return;
        }

        const emailDomain = email.substr(email.indexOf('@') + 1);

        if (emailDomain.indexOf(domain) !== -1) {
            valid = false;
        }
    });

    return valid;
}