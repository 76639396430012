export const formStyle = (formId) => {
    const form = document.getElementById(`mktoForm_${formId}`);

    if (!form) {
        return;
    }

    const loader = form.querySelector('.loader');

    if (loader) {
        loader.parentNode.removeChild(loader);
    }

    form.removeAttribute('style');
    form.style.opacity = 0;
    form.classList.add('download-report-form');

    Array.from(document.head.querySelectorAll('[id*="mkto"]')).forEach((style) => {
        style.parentNode.removeChild(style);
    });

    Array.from(form.querySelectorAll('style, .mktoClear')).forEach((style) => {
        style.parentNode.removeChild(style);
    });

    Array.from(form.querySelectorAll('input[type="hidden"]')).forEach((input) => {
        input.parentNode.classList.remove('mktoFormRow');
    });

    Array.from(form.querySelectorAll('input[type="checkbox"]')).forEach((input) => {
        input.closest('.mktoFormRow').classList.add('opt-in');
    });

    Array.from(form.querySelectorAll('[style]')).forEach((el) => {
        el.removeAttribute('style');
    });

    Array.from(form.querySelectorAll('button, input[type="submit"]')).forEach((button) => {
        button.classList.remove('mktoButton');
        button.classList.add('button', 'primary');
    });

    setTimeout(() => {
        form.style.opacity = 1;
    }, 10);
}
