import React, { useEffect, useState } from 'react';
import { injectIntl } from 'gatsby-plugin-intl';
import { getWindow } from 'ssr-window';
import { PAID_FORMS } from '../common/constants';
import { formStyle } from '../util/formStyle';
import { emailValidate } from '../util/formValidate';
import Loading from '../images/form/loading.gif';

const window = getWindow();

const PaidForm = ({ intl, updateOnSuccess }) => {
    const { locale } = intl;
    const formData = PAID_FORMS[locale] || false;

    const {
        id,
        subscriber,
        domain,
    } = formData;

    const [marketoLoaded, setMarketoLoaded] = useState(false);

    useEffect(() => {
        const existingScript = document.getElementById('marketo-forms');

        if (!existingScript) {
            const script = document.createElement('script');
            script.src = `//${domain}/js/forms2/js/forms2.min.js`;
            script.id = 'marketo-forms';
            document.body.appendChild(script);

            script.onload = () => {
                setMarketoLoaded(true)
            };
        }

    }, [locale]);

    useEffect(() => {
        if (marketoLoaded) {
            window.MktoForms2.loadForm(
                `//${domain}`,
                subscriber,
                id,
                (form) => {
                    formStyle(id);

                    form.onValidate(() => {
                        const { Email } = form.getValues();
                        if (!emailValidate(Email)) {
                            const emailElem = form.getFormElem().find("#Email");
                            form.submittable(false);
                            form.showErrorMessage(
                                intl.formatMessage({
                                    id: "blacklisted_email",
                                    defaultMessage: "Please use a valid business email address.",
                                }), emailElem);
                        } else {
                            form.submittable(true);
                        }
                    });

                    form.onSuccess(() => {
                        updateOnSuccess();
                        return false;
                    });
                });
        }

    }, [locale, marketoLoaded])

    return (
        <div className="block standard form">
            <div className="container-fluid">
                <div className="row">
                    <div className="col">
                        <h2>{intl.formatMessage({ id: "cta_download" })}</h2>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xl-10">
                        <p>{intl.formatMessage({
                            id: "paidmedia_copy_1",
                            defaultMessage: "Dive into how commerce has been completely redefined over the last year.",
                        })}</p>
                        <p>{intl.formatMessage({
                            id: "paidmedia_copy_2",
                            defaultMessage: "In The Global Payments Report, we’ll break down regional trends, so you get a snapshot into how COVID-19 altered the way people pay and how geography plays an important role in those preferences.",
                        })}</p>
                        <p>{intl.formatMessage({
                            id: "paidmedia_copy_3",
                            defaultMessage: "It’s time to discover how 2020 ultimately advanced digital payments around the world.",
                        })}</p>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <div>
                            <form id={`mktoForm_${id}`}><img src={Loading} alt="" className="loader" /></form>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default injectIntl(PaidForm);
